<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/employee">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                :podeFechar="podeFechar"
                :exibirToastSucesso="exibirToastSucesso"
                backPath="/employee"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                :disableSave="disableSave || matriculaExistente"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
                :showAtivarInativar="false"
                permission="estrutura_funcionario"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="funcionarios-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <h5 class="mb-4">Dados Pessoais</h5>

                        <div class="grid">
                            <div class="field col-5">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="name">Nome</label>
                                <InputText
                                    class="w-full"
                                    id="name"
                                    v-model.trim="form.name"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && !form.name }"
                                    @change="checkLetraAvulsa(form.name)"
                                />
                                <small class="p-error" v-if="submitted && !form.name">Nome é obrigatório.</small>
                            </div>
                            <div class="field col-4">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="cpf">CPF</label>
                                <InputMask
                                    id="cpf"
                                    mask="999.999.999-99"
                                    v-model.trim="form.cpf"
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && (!form.cpf || !cpfIsValid) }"
                                    unmask
                                />
                                <small class="p-error" v-if="submitted && !form.cpf">CPF é obrigatório.</small>
                                <small class="p-error" v-if="disableSave">CPF já cadastrado.</small>
                                <small class="p-error" v-if="submitted && form.cpf && !cpfIsValid">CPF inválido.</small>
                            </div>
                            <div class="field col-3">
                                <label for="name">Situação</label>
                                <Dropdown
                                    id="situation"
                                    v-model="form.situation"
                                    :options="situation"
                                    optionLabel="label"
                                    :class="{ 'p-invalid': submitted && !form.situation }"
                                    optionValue="value"
                                    placeholder="Situação do funcionario"
                                    required="true"
                                    :optionDisabled="getOptionDisableSituacao"
                                />
                                <small class="p-error" v-if="submitted && !form.situation">Situação é obrigatório.</small>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-3">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="sex">Sexo</label>
                                <Dropdown
                                    id="sex"
                                    v-model="form.sex"
                                    :options="sex"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Escolha"
                                    required="true"
                                    :class="{ 'p-invalid': submitted && !form.sex }"
                                />
                                <small class="p-error" v-if="submitted && !form.sex">Sexo é obrigatório.</small>
                            </div>
                            <div class="field col-2">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="data_nascimento">Data Nascimento</label>
                                <InputMask
                                    mask="99/99/9999"
                                    v-model="form.dataNascimento"
                                    :class="{ 'p-invalid': submitted && (!form.dataNascimento || !dataNascimentoIsValid) }"
                                />
                                <small class="p-error" v-if="submitted && !form.dataNascimento">Data de nascimento obrigatória.</small>
                                <small class="p-error" v-if="submitted && form.dataNascimento && !dataNascimentoIsValid">
                                    Data de nascimento inválida.
                                </small>
                                <small class="p-error" v-if="submitted && validarDataMenorQue100Anos">
                                    Data de nascimento inválida, verefique o ano.
                                </small>
                            </div>
                            <div class="field col-3">
                                <span :style="{ color: 'red' }">*</span>
                                <label for="phone">Telefone celular</label>
                                <InputMask
                                    id="phone"
                                    mask="(99) 99999-9999"
                                    v-model.trim="form.phone"
                                    autocomplete="off"
                                    unmask
                                    :class="{ 'p-invalid': submitted && (!form.phone || !validarTelefone) }"
                                />
                                <small class="p-error" v-if="!form.phone"> Telefone não informado </small>
                                <small class="p-error" v-if="form.phone && !validarTelefone(form.phone)"> Telefone inválido </small>
                            </div>
                            <div class="field col-4">
                                <label for="email">E-mail</label>
                                <InputText id="email" v-model.trim="form.email" v-on:blur="validarEmail()" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <h5 class="mb-4">Dados de Contratação</h5>
                    <div class="grid">
                        <div class="field col-3">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="admission_date">Data Admissão</label>
                            <InputMask
                                mask="99/99/9999"
                                id="admission_date"
                                v-model="form.admissionDate"
                                :class="{ 'p-invalid': submitted && !form.admissionDate }"
                            />
                            <small class="p-error" v-if="submitted && !form.admissionDate">Data Admissão é obrigatório.</small>
                            <small class="p-error" v-if="submitted && validarDataMenorQue100Anos">
                                Data de nascimento inválida, verefique o ano.
                            </small>
                        </div>
                        <div class="field col-3">
                            <span :style="{ color: 'red' }" v-if="form.situation === enumSituation.DEMITIDO">*</span>
                            <label for="resignation_date">Data Demissão</label>
                            <InputMask mask="99/99/9999" id="resignation_date" v-model="form.resignationDate" />
                            <small class="p-error" v-if="isResignationDateBeforeAdmissionDate">
                                Data de demissão não pode ser menor que a data de admissão.
                            </small>
                            <small class="p-error" v-if="form.situation === enumSituation.DEMITIDO && !form.resignationDate">
                                Data de demissão é obrigatória.
                            </small>
                        </div>
                        <div class="field col-3">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="categoriaTrabalhador">Categoria eSocial </label>
                            <DropdownCategoria
                                id="categoriaTrabalhador"
                                v-model="form.categoriaTrabalhador"
                                :class="{ 'p-invalid': submitted && !form.categoriaTrabalhador }"
                            />
                            <small class="p-error" v-if="submitted && !form.categoriaTrabalhador">Categoria eSocial é obrigatório.</small>
                        </div>
                        <div class="field col-3">
                            <label for="matriculaEsocial">Matrícula eSocial</label>
                            <InputText id="matriculaEsocial" v-model.trim="form.matriculaEsocial" autocomplete="off" required="true" />
                            <small class="p-error" v-if="matriculaExistente"> Matrícula eSocial já existe.</small>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="field col-3">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customer_id">Cliente </label>
                            <InputText
                                v-if="form?.id && form.customer"
                                id="customer"
                                :disabled="true"
                                v-model="form.customer.name"
                                autocomplete="off"
                            />
                            <DropdownClienteInscricao
                                v-if="!form?.id"
                                id="customer_id"
                                :loading="loadingCustomer"
                                :disabled="!!form.id || loadingCustomer"
                                v-on:before-hide="onCustomerChange($event)"
                                class="w-full"
                                v-model="form.customer"
                                :idValue="form.customerId"
                                :class="{ 'p-invalid': submitted && !form.customerId }"
                                @change="alterCliente"
                            />
                            <small class="p-error" v-if="submitted && !form.customerId">Cliente é obrigatório.</small>
                        </div>
                        <div class="field col-3">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customerBranchesId">Unidade</label>
                            <InputText v-if="form?.id && form.branch" id="customerBranch" :disabled="true" v-model="form.branch.name" />
                            <!-- <DropdownUnidadeTradeCode
                                v-if="!form?.id"
                                id="customerBranchId"
                                :loading="loadingHierarquia"
                                :disabled="!!form.id || loadingHierarquia || !form.customerId"
                                v-on:before-hide="onChangeFindHierarchy($event)"
                                class="w-full"
                                :customerId="form.customerId"
                                v-model="form.customerHierarchy.customerBranch"
                                :class="{ 'p-invalid': submitted && !form.customerHierarchy.customerBranchId }"
                            /> -->
                            <InfinityDropdownUnidadesByCliente
                                v-if="!form?.id"
                                id="customerBranchId"
                                :loading="loadingHierarquia"
                                v-model="form.customerHierarchy.customerBranch"
                                :disabled="!!form.id || loadingHierarquia || !form.customerId"
                                :filtrosExtras="{ customerId: form.customerId }"
                                class="w-full"
                                v-on:before-hide="onChangeFindHierarchy($event)"
                                :class="{ 'p-invalid': submitted && !form.customerHierarchy.customerBranchId }"
                                showClear
                                :filterFields="['name', 'tradeName']"
                            />

                            <small class="p-error" v-if="submitted && !form.customerHierarchy.customerBranchId && !form?.id"
                                >Unidade é obrigatório.</small
                            >
                        </div>
                        <div v-if="temPermissao()" class="field col-3">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customer_department">Setor</label>
                            <InputText v-if="form?.id && form.department" id="customerDepartment" :disabled="true" v-model="form.department.name" />
                            <DropdownSetor
                                v-if="!form?.id"
                                id="customerDepartmentId"
                                :loading="loadingHierarquia"
                                :disabled="!!form.id || loadingHierarquia || !form.customerId"
                                v-on:before-hide="onChangeFindHierarchy($event)"
                                class="w-full"
                                :customerId="form.customerId"
                                v-model="form.customerHierarchy.customerDepartment"
                                :class="{ 'p-invalid': submitted && !form.customerHierarchy.customerDepartmentId }"
                            />
                            <small class="p-error" v-if="submitted && !form.customerHierarchy.customerDepartmentId && !form?.id"
                                >Setor é obrigatório.</small
                            >
                        </div>
                        <div v-if="temPermissao()" class="field col-3">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customer_position">Cargo</label>
                            <InputText v-if="form?.id && form.position" id="customerPosition" :disabled="true" v-model="form.position.name" />
                            <DropdownCargo
                                v-if="!form?.id"
                                id="customerPositionId"
                                :loading="loadingHierarquia"
                                :disabled="!!form.id || loadingHierarquia || !form.customerId"
                                v-on:before-hide="onChangeFindHierarchy($event)"
                                class="w-full"
                                :customerId="form.customerId"
                                v-model="form.customerHierarchy.customerPosition"
                                :class="{ 'p-invalid': submitted && !form.customerHierarchy.customerPositionId }"
                            />
                            <small class="p-error" v-if="submitted && !form.customerHierarchy.customerPositionId && !form?.id"
                                >Cargo é obrigatório.</small
                            >
                        </div>
                        <div v-if="!temPermissao()" class="field col-3">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customer_department">Setor</label>
                            <InputText v-if="form?.id && form.department" id="customerDepartment" :disabled="true" v-model="form.department.name" />
                            <DropdownSetorByHierarquia
                                v-if="!form?.id"
                                id="customerDepartmentId"
                                :loading="loadingHierarquia"
                                :disabled="!!form.id || loadingHierarquia || !form.customerId"
                                v-on:before-hide="onChangeFindHierarchy($event)"
                                class="w-full"
                                :customerId="form.customerId"
                                :branchId="form.customerHierarchy.customerBranchId"
                                v-model="form.customerHierarchy.customerDepartment"
                                :class="{ 'p-invalid': submitted && !form.customerHierarchy.customerDepartmentId }"
                            />
                            <small class="p-error" v-if="submitted && !form.customerHierarchy.customerDepartmentId && !form?.id"
                                >Setor é obrigatório.</small
                            >
                        </div>
                        <div v-if="!temPermissao()" class="field col-3">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customer_position">Cargo</label>
                            <InputText v-if="form?.id && form.position" id="customerPosition" :disabled="true" v-model="form.position.name" />
                            <DropdownCargoByHierarquia
                                v-if="!form?.id"
                                id="customerPositionId"
                                :loading="loadingHierarquia"
                                :disabled="!!form.id || loadingHierarquia || !form.customerId || !form.customerHierarchy.customerDepartment"
                                v-on:before-hide="onChangeFindHierarchy($event)"
                                class="w-full"
                                :customerId="form.customerId"
                                :branchId="form.customerHierarchy.customerBranchId"
                                :departmentId="form.customerHierarchy.customerDepartmentId"
                                v-model="form.customerHierarchy.customerPosition"
                                :class="{ 'p-invalid': submitted && !form.customerHierarchy.customerPositionId }"
                            />
                            <small class="p-error" v-if="submitted && !form.customerHierarchy.customerPositionId && !form?.id"
                                >Cargo é obrigatório.</small
                            >
                        </div>
                    </div>
                    <div v-if="naoTemHierarquia && enviaEmailFuncionarioSemHierarquia" class="grid">
                        <div class="field col-6">
                            <label for="descricaoRisco">Descrição do risco</label>
                            <Textarea
                                type="text"
                                id="descricaoRisco"
                                v-model="form.descricaoRisco"
                                :class="{ 'p-invalid': submitted && !form.descricaoRisco && naoTemHierarquia && enviaEmailFuncionarioSemHierarquia }"
                            />
                        </div>
                    </div>
                    <div class="field mt-5">
                        <div class="field-checkbox">
                            <InputSwitch v-model="checkedMaisInfo" />
                            <label for="binary" class="mr-2 mb-1">Detalhes</label>
                        </div>
                    </div>
                    <div v-if="checkedMaisInfo">
                        <h5 class="mt-6 mb-4">Mais detalhes</h5>
                        <div class="grid">
                            <div class="field col-6">
                                <label for="code">Código SOC</label>
                                <InputText id="code" v-model.trim="form.codigoSoc" autocomplete="off" />
                            </div>
                            <div class="field col-6">
                                <label for="code">Matrícula Cliente</label>
                                <InputText id="code" v-model.trim="form.matriculaCliente" autocomplete="off" />
                            </div>
                        </div>

                        <div class="grid">
                            <div class="field col-6">
                                <label for="civil_state">Estado civil</label>
                                <Dropdown
                                    id="civil_state"
                                    v-model="form.civilState"
                                    :options="civil_state"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Escolha"
                                    required="true"
                                />
                            </div>
                            <div class="field col-6">
                                <label for="nomeSocial">Nome Social</label>
                                <InputText
                                    class="w-full"
                                    id="nomeSocial"
                                    v-model.trim="form.nomeSocial"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                />
                            </div>
                        </div>

                        <div class="grid">
                            <div class="field col-12">
                                <label for="commercial_phone">Telefone comercial</label>
                                <InputMask
                                    id="commercial_phone"
                                    mask="(99) 9999-9999"
                                    v-model.trim="form.commercialPhone"
                                    autocomplete="off"
                                    unmask
                                />
                            </div>
                        </div>

                        <div class="field mt-5">
                            <div class="field-checkbox">
                                <InputSwitch v-model="form.pcd" v-tooltip="'Pessoa com Deficiência'" />
                                <label for="binary" class="mr-2 mb-1">PCD</label>
                            </div>
                        </div>

                        <div class="field" v-if="form.pcd">
                            <label for="deficiency">Tipo</label>
                            <Dropdown
                                id="deficiency"
                                v-model="form.deficiency"
                                :options="deficiency"
                                optionLabel="label"
                                optionValue="label"
                                placeholder="Escolha"
                                required="true"
                                :showClear="true"
                            />
                        </div>

                        <h5 class="mt-6 mb-4">Documentos pessoais</h5>

                        <div class="grid">
                            <div class="field col-6">
                                <label for="rg">RG</label>
                                <InputText id="rg" v-model.trim="form.rg" autocomplete="off" />
                            </div>
                            <div class="field col-6">
                                <label for="rg_dispatcher">Órgão Expedidor</label>
                                <InputText id="rg_dispatcher" v-model.trim="form.rgDispatcher" autocomplete="off" />
                            </div>
                        </div>

                        <div class="grid">
                            <div class="field col-6">
                                <label for="rg_date">Data da emissão</label>
                                <Calendar :manualInput="true" id="rg_date" dateFormat="dd/mm/yy" v-model="form.rgDate" :show-icon="true" />
                            </div>

                            <div class="field col-6">
                                <label for="rg_state">Estado</label>
                                <Dropdown
                                    id="rg_state"
                                    v-model="form.rgState"
                                    :options="states"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Selecione um estado"
                                />
                            </div>
                        </div>

                        <h5 class="mt-6 mb-4">Carteira de trabalho</h5>

                        <div class="grid">
                            <div class="field col-6">
                                <label for="ctps">CTPS</label>
                                <InputText id="ctps" v-model.trim="form.ctps" autocomplete="off" required="true" />
                            </div>
                            <div class="field col-6">
                                <label for="ctps_state">Estado</label>
                                <Dropdown
                                    id="ctps_state"
                                    v-model="form.ctpsState"
                                    :options="states"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Selecione um estado"
                                    required="true"
                                />
                            </div>
                            <div class="field col-6">
                                <label for="pis_pasep">PIS/PASEP</label>
                                <InputMask
                                    id="pis_pasep"
                                    mask="999.99999.99-9"
                                    v-model.trim="form.pisPasep"
                                    autocomplete="off"
                                    required="true"
                                    unmask
                                />
                            </div>
                        </div>

                        <h5 class="mt-6 mb-4">Endereço</h5>
                        <div>
                            <div class="field">
                                <label for="address">Rua</label>
                                <InputText id="address" v-model.trim="form.address" autocomplete="off" required="true" />
                            </div>

                            <div class="grid">
                                <div class="field col-6">
                                    <label for="number">Número</label>
                                    <InputText id="number" v-model.trim="form.number" autocomplete="off" />
                                </div>

                                <div class="field col-6">
                                    <label for="address_complement">Complemento</label>
                                    <InputText id="address_complement" v-model.trim="form.addressComplement" autocomplete="off" />
                                </div>
                            </div>

                            <div class="grid">
                                <div class="field col-6">
                                    <label for="neighborhood">Bairro</label>
                                    <InputText id="neighborhood" v-model.trim="form.neighborhood" autocomplete="off" required="true" />
                                </div>

                                <div class="field col-6">
                                    <label for="zip_code">CEP</label>
                                    <InputMask id="zip_code" mask="99999-999" v-model.trim="form.zipCode" autocomplete="off" required="true" />
                                </div>
                            </div>

                            <div class="grid">
                                <div class="field col-6">
                                    <label for="city">Cidade</label>
                                    <InputText id="city" v-model.trim="form.city" autocomplete="off" required="true" />
                                </div>

                                <div class="field col-6">
                                    <label for="state">Estado</label>
                                    <Dropdown
                                        id="state"
                                        v-model="form.state"
                                        :options="states"
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Selecione um estado"
                                        required="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
    <MultiplosVinculosDialog v-model:visible="showMultiplo" v-bind:form="form" v-bind:formAntigo="formAntigo" v-bind:fecharMultiplo="fecharMultiplo">
    </MultiplosVinculosDialog>
    <AppLogErroDialog
        v-if="showLogsDialog"
        v-model:visible="showLogsDialog"
        title="Logs para o funcionário"
        origem="CADASTRO_FUNCIONARIO"
        v-model:origemId="form.id"
        @onClose="showLogsDialog = false"
    />
</template>
<script>
import moment from 'moment-timezone';
import BaseService from '../../services/BaseService';
import { getClientBase } from '@/services/http';
import SexEnum from '../../enums/SexEnum';
import SituationEnum from '../../enums/SituationEnum';
import MultiplosVinculosDialog from './components/MultiplosVinculosDialog.vue';
import DropdownCategoria from './components/DropdownCategoria.vue';
import { getCurrentCompany } from '@/services/store';
import DropdownClienteInscricao from '../clientes/components/DropdownClienteInscricao.vue';
import DropdownSetor from './components/DropDownsPorCliente/DropdownSetorByCliente.vue';
import DropdownSetorByHierarquia from './components/DropDownsPorHierarquia/DropdownSetorByHierarquia.vue';
import DropdownCargo from './components/DropDownsPorCliente/DropdownCargoByCliente.vue';
import DropdownCargoByHierarquia from './components/DropDownsPorHierarquia/DropdownCargoByHierarquia.vue';
import { validateCPF } from '../../utils/ValidateCpfCnpj';
import AppInfoManual from '../../components/AppInfoManual.vue';
import InfinityDropdownUnidadesByCliente from '../unidades/components/InfinityDropdownUnidadesByCliente.vue';

export default {
    components: {
        MultiplosVinculosDialog,
        DropdownClienteInscricao,
        DropdownSetor,
        DropdownCargo,
        DropdownCategoria,
        DropdownSetorByHierarquia,
        DropdownCargoByHierarquia,
        AppInfoManual,
        InfinityDropdownUnidadesByCliente
    },
    data() {
        return {
            enviaEmailFuncionarioSemHierarquia: false,
            naoTemHierarquia: false,
            showLogsDialog: false,
            loadingCustomer: false,
            loadingHierarquia: false,
            validarInvalido: false,
            checkedMaisInfo: false,
            checkedDeficiente: false,
            matriculaExistente: false,
            showMultiplo: false,
            hasHierarchy: false,
            cpfEncontrado: false,
            disableSave: false,
            podeFechar: true,
            exibirToastSucesso: true,
            form: {
                customerHierarchy: {}
            },
            formAntigo: {},
            hierarquia: {
                cargo: null,
                setor: null,
                unidade: null
            },
            submitted: false,
            customers: [],
            departments: [],
            branches: [],
            positions: [],
            sex: [],
            enumSituation: SituationEnum,
            dataNascimentoIsValid: true,
            cpfIsValid: true,
            situation: [],
            civil_state: [
                { label: 'Casado', value: '1' },
                { label: 'Solteiro', value: '2' },
                { label: 'Divorciado', value: '3' },
                { label: 'Separado', value: '4' },
                { label: 'Viúvo', value: '5' }
            ],
            deficiency: [
                { label: 'Deficiência Visual' },
                { label: 'Deficiência Auditiva' },
                { label: 'Deficiência Física' },
                { label: 'Deficiência Mental' },
                { label: 'Deficiência Intelectual' }
            ],
            states: [
                { value: null, label: 'Selecione um estado' },
                { value: 'AC', label: 'Acre' },
                { value: 'AL', label: 'Alagoas' },
                { value: 'AP', label: 'Amapá' },
                { value: 'AM', label: 'Amazonas' },
                { value: 'BA', label: 'Bahia' },
                { value: 'CE', label: 'Ceará' },
                { value: 'DF', label: 'Distrito Federal' },
                { value: 'ES', label: 'Espírito Santo' },
                { value: 'GO', label: 'Goiás' },
                { value: 'MA', label: 'Maranhão' },
                { value: 'MT', label: 'Mato Grosso' },
                { value: 'MS', label: 'Mato Grosso do Sul' },
                { value: 'MG', label: 'Minas Gerais' },
                { value: 'PA', label: 'Pará' },
                { value: 'PB', label: 'Paraíba' },
                { value: 'PR', label: 'Paraná' },
                { value: 'PE', label: 'Pernambuco' },
                { value: 'PI', label: 'Piauí' },
                { value: 'RJ', label: 'Rio de Janeiro' },
                { value: 'RN', label: 'Rio Grande do Norte' },
                { value: 'RS', label: 'Rio Grande do Sul' },
                { value: 'RO', label: 'Rondônia' },
                { value: 'RR', label: 'Roraima' },
                { value: 'SC', label: 'Santa Catarina' },
                { value: 'SP', label: 'São Paulo' },
                { value: 'SE', label: 'Sergipe' },
                { value: 'TO', label: 'Tocantins' }
            ]
        };
    },
    created() {
        this.service = new BaseService('/customer/employees');
        this.$serviceCustomer = new BaseService('/customers/combo');
    },
    async mounted() {
        this.$service = new BaseService('/customer/employees');
        this.$serviceHierarchy = new BaseService('/customer/hierarchies/has-hierarchy');
        this.$serviceCustomer = new BaseService('customers/combo');
        this.$serviceBranches = new BaseService('/customer/branches/combo');
        this.$serviceDepartment = new BaseService('/customer/departments/combo');
        this.$servicePositions = new BaseService('/customer/positions/combo');
        this.form.situation = this.enumSituation.PENDENTE;
        this.loadCustomers();
        this.sexOptions();
        this.situationOptions();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar funcionário' : 'Adicionar funcionário';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        },
        // maiorDe13Anos(){
        //     const dataAtual = moment().format('DD/MM/YYYY');
        //     const dataNacis = this.form?.dataNascimento?.split('/');
        //     const formatoData = this.onFormatDateSave(dataNacis);
        //     const difEntreDatas = moment(moment(dataAtual).toDate()).diff(formatoData, 'years');

        //     if(difEntreDatas > 13){
        //         return true;
        //     }

        //     return false;
        // },
        validarDatas() {
            const dataAdmissional = this.form?.admissionDate?.split('/');
            const dataNascismento = this.form?.dataNascimento?.split('/');
            const dataAdmissionalFormatada = this.onFormatDateSave(dataAdmissional);
            const dataNascimentoFormatada = this.onFormatDateSave(dataNascismento);
            const dataAgora = moment().toDate();
            if (moment(dataAdmissionalFormatada).isAfter(dataNascimentoFormatada) || moment(dataAgora).isAfter(dataNascimentoFormatada)) {
                return true;
            }

            return false;
        },
        validarDataMenorQue100Anos() {
            const dataAdmissional = this.form?.admissionDate?.split('/');
            const dataNascismento = this.form?.dataNascimento?.split('/');
            const dataAdmissionalFormatada = this.onFormatDateSave(dataAdmissional);
            const dataNascimentoFormatada = this.onFormatDateSave(dataNascismento);
            const dataAgora = moment().toDate();
            if (
                moment(dataAdmissionalFormatada).year() < moment(dataAgora).add(-99, 'years').year() ||
                moment(dataNascimentoFormatada).year() < moment(dataAgora).add(-99, 'years').year()
            ) {
                return true;
            }

            return false;
        },
        isResignationDateBeforeAdmissionDate() {
            const resignationDate = moment(this.form.resignationDate, 'DD/MM/YYYY');
            const admissionDate = moment(this.form.admissionDate, 'DD/MM/YYYY');
            return resignationDate.isBefore(admissionDate);
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        },
        'form.cpf'() {
            if (this.form.cpf && this.form.customerId) {
                this.getCpfCadastrado();
            }
            this.cpfIsValid = validateCPF(this.form.cpf);
        },
        'form.dataNascimento'() {
            const dataAdmissional = this.form?.admissionDate?.split('/');
            const dataNascismento = this.form?.dataNascimento?.split('/');
            const dataAdmissionalFormatada = this.onFormatDateSave(dataAdmissional);
            const dataNascimentoFormatada = this.onFormatDateSave(dataNascismento);
            if (moment(dataAdmissionalFormatada).isAfter(dataNascimentoFormatada)) {
                this.dataNascimentoIsValid = true;
            } else {
                this.dataNascimentoIsValid = false;
            }
        },
        'form.admissionDate'() {
            const dataAdmissional = this.form?.admissionDate?.split('/');
            const dataNascismento = this.form?.dataNascimento?.split('/');
            const dataAdmissionalFormatada = this.onFormatDateSave(dataAdmissional);
            const dataNascimentoFormatada = this.onFormatDateSave(dataNascismento);
            if (moment(dataAdmissionalFormatada).isAfter(dataNascimentoFormatada)) {
                this.dataNascimentoIsValid = true;
            } else {
                this.dataNascimentoIsValid = false;
            }
        },
        'form.customerId'() {
            if (this.form.cpf && this.form.customerId) {
                this.getCpfCadastrado();
            }
        },
        'form.matriculaEsocial'() {
            if (this.form.matriculaEsocial && this.form.customerId) {
                this.getMatriculaEsocialCadastrado();
            }
        },
        'form.customer'() {
            this.form.customerId = this.form.customer?.id;
            this.form.customerHierarchy.customerPosition = null;
            this.form.customerHierarchy.customerDepartment = null;
            this.form.customerHierarchy.customerBranch = null;
        },
        'form.customerHierarchy.customerBranch'() {
            this.form.customerHierarchy.customerBranchId = this.form.customerHierarchy?.customerBranch?.id;
            this.form.customerHierarchy.customerPosition = null;
            this.form.customerHierarchy.customerDepartment = null;
        },
        'form.customerHierarchy.customerDepartment'() {
            this.form.customerHierarchy.customerDepartmentId = this.form.customerHierarchy?.customerDepartment?.id;
            this.form.customerHierarchy.customerPosition = null;
        },
        'form.customerHierarchy.customerPosition'() {
            this.form.customerHierarchy.customerPositionId = this.form.customerHierarchy?.customerPosition?.id;
        }
    },
    methods: {
        validarTelefone(telefone) {
            const regexTelefone = /^(?!.*(\d)\1{10}|1234567890|9876543210)\d{11}$/;

            if (regexTelefone.test(telefone)) {
                return true;
            }
            return false;
        },
        alterCliente(record) {
            this.enviaEmailFuncionarioSemHierarquia = record?.value?.enviaEmailFuncionarioSemHierarquia;
        },
        onFormatDateSave(date) {
            return date ? moment(`${date[2]}-${date[1]}-${date[0]}`).toDate() : null;
        },
        getOptionDisableSituacao(option) {
            if (!this.$route.params.id && option.value != this.enumSituation.PENDENTE) {
                return true;
            }
            if (option.value === this.enumSituation.ATIVO && this.$route.params.id) {
                return !this.$checkPermission('estrutura_funcionario:ativar');
            }

            if (option.value === this.enumSituation.PENDENTE && this.$route.params.id) {
                return !this.$checkPermission('estrutura_funcionario:ativar');
            }

            if (option.value === this.enumSituation.FERIAS && this.$route.params.id) {
                return !this.$checkPermission('estrutura_funcionario:ativar');
            }

            if (option.value === this.enumSituation.AFASTADO && this.$route.params.id) {
                return !this.$checkPermission('estrutura_funcionario:ativar');
            }

            if (option.value === this.enumSituation.INATIVO) {
                return !this.$checkPermission('estrutura_funcionario:inativar');
            }

            if (option.value === this.enumSituation.DEMITIDO && this.$route.params.id) {
                return !this.$checkPermission('estrutura_funcionario:inativar');
            }

            return false;
        },
        temPermissao() {
            return this.$checkPermission('estrutura_funcionario:exibir_sem_filtro_de_hierarquia');
        },
        validarEmail() {
            const emailRegex =
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            const validacao = emailRegex.test(this.form.email);
            if (!validacao) {
                this.form.email = null;
                this.$toast.add({ severity: 'error', summary: 'Email invalido! ', life: 3000 });
                return true;
            }
        },

        fecharMultiplo() {
            this.showMultiplo = false;
            if (this.form.multipleLinks === true) {
                this.disableSave = false;
            } else {
                this.disableSave = true;
                this.form.multipleLinks = false;
            }
        },
        sexOptions() {
            this.sex = Object.keys(SexEnum).map(function (type) {
                return { label: `${SexEnum[type]}`, value: `${type}` };
            });
        },
        situationOptions() {
            this.situation = Object.keys(SituationEnum).map(function (type) {
                return { label: `${SituationEnum[type]}`, value: `${type}` };
            });
        },
        async getCpfCadastrado() {
            this.disableSave = false;
            const funcionarios = await getClientBase().get(`customer/employees/get-by-cpf/${this.form.customerId}/${this.form.cpf}/`);
            if (!this.form.id && funcionarios.data.length > 0 && this.form.cpf != null) {
                this.showMultiplo = true;
            }
        },
        async getMatriculaEsocialCadastrado() {
            this.matriculaExistente = false;
            const funcionarios = await getClientBase().get(
                `customer/employees/get-by-registration/${this.form.customerId}/${this.form.matriculaEsocial}/`
            );
            if (!this.form.id && funcionarios.length > 0 && this.form.matriculaEsocial != null) {
                this.matriculaExistente = true;
            }
        },
        async checkLetraAvulsa(nome) {
            const contemLetraAvulsa = !!nome.split(' ').filter((p) => p.length == 1).length;
            const letraAvulsa = nome.split(' ').filter((p) => p.length == 1)[0];
            if (contemLetraAvulsa) {
                this.$toast.add({
                    severity: 'warn',
                    summary:
                        'Evitar utilizar abreviações no nome do funcionário. Se realmente possuir a letra "' +
                        letraAvulsa +
                        '" de forma avulsa, ignorar essa mensagem.',
                    life: 5000
                });
            }
        },
        async onChangeFindHierarchy() {
            this.$toast.removeAllGroups();
            this.loadingHierarquia = true;

            if (
                this.form.customerId &&
                this.form.customerHierarchy.customerBranchId &&
                this.form.customerHierarchy.customerDepartmentId &&
                this.form.customerHierarchy.customerPositionId
            ) {
                const { data } = await this.$serviceHierarchy.findAll({
                    customerId: this.form.customerId,
                    customerBranchId: this.form.customerHierarchy.customerBranchId,
                    customerDepartmentId: this.form.customerHierarchy.customerDepartmentId,
                    customerPositionId: this.form.customerHierarchy.customerPositionId
                });

                this.naoTemHierarquia = !data?.id;
                this.form.customerHierarchy.id = data?.id;
                if (this.naoTemHierarquia) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Hierarquia não encontrada!',
                        life: 3000
                    });
                }
            }

            this.loadingHierarquia = false;
        },

        async onCustomerChange() {
            this.form.customerHierarchy.customerPosition = null;
            this.form.customerHierarchy.customerDepartment = null;
            this.form.customerHierarchy.customerBranch = null;
        },
        async onBeforeSave() {
            this.submitted = true;

            if (this.validarDataMenorQue100Anos) {
                return this.$toast.add({
                    severity: 'info',
                    summary: 'A data informada não é válida',
                    life: 3000
                });
            }

            if (!this.validarDatas) {
                return this.$toast.add({
                    severity: 'info',
                    summary: 'A data de admissão menor do que a de nascimento.',
                    life: 3000
                });
            }

            if (!this.dataNascimentoIsValid) {
                return this.$toast.add({
                    severity: 'info',
                    summary: 'A data de admissão menor do que a de nascimento.',
                    life: 3000
                });
            }

            if (!this.cpfIsValid) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'CPF inválido!',
                    life: 3000
                });
                document.getElementById('cpf').focus();
                return;
            }
            if (!this.cpfIsValid) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'CPF inválido!',
                    life: 3000
                });
                document.getElementById('cpf').focus();
                return;
            }
            if (!this.dataNascimentoIsValid) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Não é possível incluir o funcionário com a data de nascimento informada!',
                    life: 3000
                });
                document.getElementById('data_nascimento').focus();
                return;
            }
            if (!this.form.situation) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'A situação do funcionário não pode ser nula, a mesma deve ser colocada como pendente!',
                    life: 3000
                });
                document.getElementById('situation').focus();
                return;
            }
            if (this.form.situation === this.enumSituation.DEMITIDO && !this.form.resignationDate) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'A data de demissão é obrigatória!',
                    life: 3000
                });
                document.getElementById('resignation_date').focus();
                return;
            }
            if (this.form.situation != this.enumSituation.PENDENTE && !this.form?.id) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'A situação do funcionário deve ser incluída como pendente em um novo cadastro!',
                    life: 3000
                });
                document.getElementById('situation').focus();
                return;
            }
            if (this.hasError()) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Há campos obrigatórios não preenchidos',
                    life: 3000
                });
            }
        },
        async onAfterSave(response) {
            this.form = response.data;
            this.formatFormDatas();
            this.podeFechar = true;
            this.exibirToastSucesso = true;

            if (response.data.statusIntegracaoSoc == 'ERRO') {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Verifique o log de erros, realize as correções e tente salvar novamente.',
                    life: 3000
                });
                this.showLogsDialog = true;
                this.podeFechar = false;
                this.exibirToastSucesso = false;
            }

            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
            this.formatFormDatas();
        },
        formatFormDatas() {
            this.form.admissionDate = this.form.admissionDate ? this.$filters.formatDateOnly(this.form.admissionDate) : null;
            this.form.dataNascimento = this.form.dataNascimento ? this.$filters.formatDateOnly(this.form.dataNascimento) : null;
            this.form.resignationDate = this.form.resignationDate ? this.$filters.formatDateOnly(this.form.resignationDate) : null;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.form.customerId) return true;
            if (!this.form.admissionDate) return true;
            if (!this.form.dataNascimento || !this.dataNascimentoIsValid) return true;
            if (!this.form.sex) return true;
            if (!this.form.cpf || !this.cpfIsValid) return true;
            if (!this.form.name) return true;
            if (!this.form.customerHierarchy.customerBranchId && !this.form?.id) return true;
            if (!this.form.customerHierarchy.customerDepartmentId && !this.form?.id) return true;
            if (!this.form.customerHierarchy.customerPositionId && !this.form?.id) return true;
            if (!this.form.categoriaTrabalhador) return true;
            if (this.form.situation === this.enumSituation.DEMITIDO && !this.form.resignationDate) return true;
            if (!this.validarDatas) return true;
            if (this.validarDataMenorQue100Anos) return true;
            if (!this.validarTelefone(this.form.phone) || !this.form.phone) return true;
        },
        async loadCustomers() {
            const { data } = await this.$serviceCustomer.findAll({});
            this.customers = data;
        }
    }
};
</script>

<style scoped lang="scss">
#customer_id {
    border-radius: 6px;
}
</style>
